import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Timeline from './pages/Timeline';
import Sandbox from './pages/Sandbox';
import AboutKooki from './pages/AboutKooki';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/sandbox" element={<Sandbox />} />
          <Route path="/about-kooki" element={<AboutKooki />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
