import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTextSlideshow } from '../hooks/useTextSlideshow';

const Home = () => {
  const navigate = useNavigate();

  const handleExploreMetaverse = () => {
    window.open('/sandbox', '_blank');
  };

  const heroTexts = [
    "Welcome to KAOS UNIVERSE",
    "Experience Digital Evolution",
    "Build Your Virtual Empire",
    "Join the Future of Web3"
  ];

  const heroSubtexts = [
    "Where Digital Dreams Become Reality",
    "Redefining Virtual Experiences",
    "Own, Create, and Thrive",
    "The Next Generation Metaverse"
  ];

  const { currentText: mainText, isAnimating: mainAnimating } = useTextSlideshow(heroTexts);
  const { currentText: subText, isAnimating: subAnimating } = useTextSlideshow(heroSubtexts);

  const features = [
    {
      title: "Virtual Land Ownership",
      description: "Own and customize your piece of the KAOS metaverse with unique NFT-backed virtual land.",
      icon: "🌍"
    },
    {
      title: "KOOKI Token Integration",
      description: "Power your virtual experiences with KOOKI, the native currency of the KAOS ecosystem.",
      icon: "💎"
    },
    {
      title: "Community Governance",
      description: "Shape the future of KAOS through decentralized voting and proposal systems.",
      icon: "⚡"
    }
  ];

  const timelinePreview = [
    {
      date: "Q2 2024",
      title: "KOOKI Token Launch",
      description: "Introduction of KOOKI token and NFT marketplace integration.",
      icon: "💎"
    },
    {
      date: "Q3 2024",
      title: "Land Sale Phase 1",
      description: "First wave of virtual land plots available for purchase.",
      icon: "🌍"
    },
    {
      date: "Q4 2024",
      title: "Social Features",
      description: "Implementation of social hubs and community spaces.",
      icon: "👥"
    }
  ];

  const tokenHighlights = [
    {
      title: "Utility Token",
      description: "KOOKI serves as the primary currency for all transactions within the KAOS UNIVERSE",
      icon: "💫"
    },
    {
      title: "Community Driven",
      description: "Token holders participate in governance decisions shaping the future of the platform",
      icon: "👥"
    },
    {
      title: "Deflationary",
      description: "Built-in burn mechanism ensures long-term value appreciation",
      icon: "🔥"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Hero Section with Slider */}
      <section className="relative h-screen flex items-center justify-center bg-gradient-to-r from-purple-900 via-gray-900 to-pink-900">
        <div className="absolute inset-0 opacity-20 bg-grid"></div>
        <div className="text-center z-10 px-4">
          <motion.h1 
            key={mainText}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: mainAnimating ? 0 : 1, y: mainAnimating ? 20 : 0 }}
            transition={{ duration: 0.5 }}
            className="text-7xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text"
          >
            {mainText}
          </motion.h1>
          <motion.p 
            key={subText}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: subAnimating ? 0 : 1, y: subAnimating ? 20 : 0 }}
            transition={{ duration: 0.5 }}
            className="text-2xl mb-12 text-gray-300"
          >
            {subText}
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="flex justify-center gap-6"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-8 py-4 rounded-lg text-lg font-bold shadow-lg hover:shadow-purple-500/50 transition-shadow"
              onClick={handleExploreMetaverse}
            >
              Explore Metaverse
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="border-2 border-purple-500 text-white px-8 py-4 rounded-lg text-lg font-bold hover:bg-purple-500/20 transition-colors"
            >
              Learn More
            </motion.button>
          </motion.div>
        </div>

        {/* Progress Indicators */}
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {heroTexts.map((_, index) => (
            <motion.div
              key={index}
              className={`w-2 h-2 rounded-full ${
                index === heroTexts.indexOf(mainText)
                  ? 'bg-purple-400'
                  : 'bg-gray-600'
              }`}
              whileHover={{ scale: 1.2 }}
            />
          ))}
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl shadow-xl hover:shadow-purple-500/20 transition-shadow"
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-2xl font-bold mb-4 text-purple-400">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Timeline Preview Section */}
      <section className="py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Upcoming Milestones
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {timelinePreview.map((milestone, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl shadow-xl hover:shadow-purple-500/20 transition-shadow relative overflow-hidden"
              >
                <div className="absolute top-0 right-0 bg-purple-500/10 px-4 py-2 rounded-bl-lg">
                  {milestone.date}
                </div>
                <div className="text-4xl mb-4">{milestone.icon}</div>
                <h3 className="text-2xl font-bold mb-4 text-purple-400">{milestone.title}</h3>
                <p className="text-gray-400">{milestone.description}</p>
              </motion.div>
            ))}
          </div>
          <div className="text-center mt-12">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => window.open('/timeline', '_blank')}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-8 py-4 rounded-lg text-lg font-bold shadow-lg hover:shadow-purple-500/50 transition-shadow"
            >
              View Full Roadmap
            </motion.button>
          </div>
        </div>
      </section>

      {/* About KOOKI Section */}
      <section className="py-20 bg-gradient-to-b from-gray-900 via-purple-900/20 to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            About KOOKI Token
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {tokenHighlights.map((highlight, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-purple-500/10 to-pink-500/10 p-8 rounded-2xl border border-purple-500/20 hover:border-purple-500/40 transition-colors"
              >
                <div className="text-4xl mb-4">{highlight.icon}</div>
                <h3 className="text-2xl font-bold text-purple-400 mb-2">{highlight.title}</h3>
                <p className="text-gray-400">{highlight.description}</p>
              </motion.div>
            ))}
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.open('/about-kooki', '_blank')}
            className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-8 py-4 rounded-lg text-lg font-bold shadow-lg hover:shadow-purple-500/50 transition-shadow mx-auto block"
          >
            Learn More About KOOKI
          </motion.button>
        </div>
      </section>
    </div>
  );
};

export default Home; 