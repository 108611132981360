import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Navbar = () => {
  const handleEnterMetaverse = () => {
    window.open('/sandbox', '_blank');
  };

  return (
    <nav className="bg-gradient-to-r from-gray-900 to-purple-900 text-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="text-3xl font-bold bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text hover:scale-105 transition-transform">
              KAOS UNIVERSE
            </Link>
          </div>
          
          <div className="flex items-center space-x-8">
            <Link to="/" className="text-gray-200 hover:text-purple-400 transition-colors">Home</Link>
            <Link to="/timeline" className="text-gray-200 hover:text-purple-400 transition-colors">Timeline</Link>
            <Link to="/about-kooki" className="text-gray-200 hover:text-purple-400 transition-colors">About KOOKI</Link>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-3 rounded-lg font-bold shadow-lg hover:shadow-purple-500/50 transition-shadow"
              onClick={handleEnterMetaverse}
            >
              Enter Metaverse
            </motion.button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 