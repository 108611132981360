import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          <div>
            <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">KAOS UNIVERSE</h3>
            <p className="text-gray-400">Shaping the future of digital interaction through immersive experiences.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-purple-400">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-gray-400 hover:text-purple-400 transition-colors">Home</Link></li>
              <li><Link to="/timeline" className="text-gray-400 hover:text-purple-400 transition-colors">Timeline</Link></li>
              <li><Link to="/sandbox" className="text-gray-400 hover:text-purple-400 transition-colors">Sandbox</Link></li>
              <li><Link to="/about-kooki" className="text-gray-400 hover:text-purple-400 transition-colors">About KOOKI</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-purple-400">Community</h3>
            <div className="space-y-2">
              <a href="https://discord.gg/kaos" className="block text-gray-400 hover:text-purple-400 transition-colors">Discord</a>
              <a href="https://twitter.com/kaos" className="block text-gray-400 hover:text-purple-400 transition-colors">Twitter</a>
              <a href="https://t.me/kaos" className="block text-gray-400 hover:text-purple-400 transition-colors">Telegram</a>
              <a href="https://medium.com/kaos" className="block text-gray-400 hover:text-purple-400 transition-colors">Medium</a>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-purple-400">Newsletter</h3>
            <p className="text-gray-400 mb-4">Stay updated with our latest developments</p>
            <input 
              type="email" 
              placeholder="Enter your email" 
              className="w-full px-4 py-2 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-800 text-center">
          <p className="text-gray-400">&copy; {new Date().getFullYear()} KAOS UNIVERSE. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 