import { useState, useEffect } from 'react';

export const useTextSlideshow = (texts: string[], interval: number = 3000) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % texts.length);
        setIsAnimating(false);
      }, 500); // Half a second for fade out
    }, interval);

    return () => clearInterval(timer);
  }, [texts, interval]);

  return { currentText: texts[currentIndex], isAnimating };
}; 