import React, { useEffect } from 'react';

declare global {
  interface Window {
    createUnityInstance: any;
    unityInstance: any;
  }
}

interface UnityGameProps {
  loaderUrl: string;
  dataUrl: string;
  frameworkUrl: string;
  codeUrl: string;
}

const UnityGame: React.FC<UnityGameProps> = ({ loaderUrl, dataUrl, frameworkUrl, codeUrl }) => {
  useEffect(() => {
    const loadUnity = async () => {
      const container = document.querySelector("#unity-container");
      const canvas = document.querySelector("#unity-canvas");
      const loadingBar = document.querySelector("#unity-loading-bar");
      const progressBarFull = document.querySelector("#unity-progress-bar-full");

      try {
        window.unityInstance = await window.createUnityInstance(canvas, {
          dataUrl: dataUrl,
          frameworkUrl: frameworkUrl,
          codeUrl: codeUrl,
          streamingAssetsUrl: "StreamingAssets",
          companyName: "KAOS UNIVERSE",
          productName: "KAOS Metaverse",
          productVersion: "1.0",
        }, (progress: number) => {
          if (progressBarFull) {
            (progressBarFull as HTMLElement).style.width = `${100 * progress}%`;
          }
        });

        if (loadingBar) {
          (loadingBar as HTMLElement).style.display = "none";
        }
      } catch (error) {
        console.error("Unity load error:", error);
      }
    };

    loadUnity();

    return () => {
      if (window.unityInstance) {
        window.unityInstance.Quit();
      }
    };
  }, [dataUrl, frameworkUrl, codeUrl]);

  return (
    <div id="unity-container" className="w-full h-full bg-gray-900">
      <canvas id="unity-canvas" className="w-full h-full bg-gray-800"></canvas>
      <div id="unity-loading-bar" className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="w-48 h-2 bg-gray-700 rounded-full overflow-hidden">
          <div id="unity-progress-bar-full" className="h-full w-0 bg-purple-500 transition-all duration-200"></div>
        </div>
        <p className="text-white text-center mt-2">Loading...</p>
      </div>
    </div>
  );
};

export default UnityGame; 