import React from 'react';
import { motion } from 'framer-motion';

const AboutKooki = () => {
  const tokenomics = [
    { label: "Total Supply", value: "1,000,000,000 KOOKI" },
    { label: "Initial Circulating Supply", value: "100,000,000 KOOKI" },
    { label: "Public Sale", value: "30%" },
    { label: "Team & Advisors", value: "15%" },
    { label: "Development Fund", value: "20%" },
    { label: "Community Rewards", value: "25%" },
    { label: "Marketing", value: "10%" }
  ];

  const utilities = [
    {
      title: "Land Purchases",
      description: "Buy and trade virtual land plots in the KAOS metaverse",
      icon: "🏞️"
    },
    {
      title: "Governance",
      description: "Participate in community decisions and protocol upgrades",
      icon: "⚖️"
    },
    {
      title: "Staking Rewards",
      description: "Earn passive income by staking your KOOKI tokens",
      icon: "💰"
    },
    {
      title: "NFT Marketplace",
      description: "Trade virtual assets and collectibles using KOOKI",
      icon: "🎨"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white py-20">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            KOOKI Token
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            The native cryptocurrency powering the KAOS UNIVERSE ecosystem, enabling seamless transactions, governance, and rewards.
          </p>
        </motion.div>

        {/* Token Stats */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-gradient-to-br from-purple-500/10 to-pink-500/10 p-6 rounded-2xl border border-purple-500/20"
          >
            <h3 className="text-2xl font-bold text-purple-400 mb-2">Market Cap</h3>
            <p className="text-4xl font-bold text-white">$10M</p>
            <p className="text-gray-400">Initial Valuation</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-gradient-to-br from-purple-500/10 to-pink-500/10 p-6 rounded-2xl border border-purple-500/20"
          >
            <h3 className="text-2xl font-bold text-purple-400 mb-2">Token Price</h3>
            <p className="text-4xl font-bold text-white">$0.01</p>
            <p className="text-gray-400">Initial Price</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-gradient-to-br from-purple-500/10 to-pink-500/10 p-6 rounded-2xl border border-purple-500/20"
          >
            <h3 className="text-2xl font-bold text-purple-400 mb-2">Total Supply</h3>
            <p className="text-4xl font-bold text-white">1B</p>
            <p className="text-gray-400">Fixed Supply</p>
          </motion.div>
        </div>

        {/* Tokenomics */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mb-20"
        >
          <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Tokenomics
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl">
              <ul className="space-y-4">
                {tokenomics.map((item, index) => (
                  <li key={index} className="flex justify-between items-center border-b border-gray-700 pb-2">
                    <span className="text-gray-300">{item.label}</span>
                    <span className="text-purple-400 font-bold">{item.value}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl">
              <div className="aspect-square relative">
                {/* Add a pie chart or other visualization here */}
                <div className="absolute inset-0 flex items-center justify-center">
                  <p className="text-center text-gray-400">Token Distribution</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Utility */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Token Utility
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {utilities.map((utility, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-2xl"
              >
                <div className="text-4xl mb-4">{utility.icon}</div>
                <h3 className="text-xl font-bold text-purple-400 mb-2">{utility.title}</h3>
                <p className="text-gray-400">{utility.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutKooki; 