import React from 'react';
import { motion } from 'framer-motion';

const Timeline = () => {
  const timelineEvents = [
    {
      date: "Q1 2024",
      title: "Project Launch",
      description: "Initial release of KAOS UNIVERSE platform with basic metaverse functionality.",
      status: "Completed",
      icon: "🚀"
    },
    {
      date: "Q2 2024",
      title: "KOOKI Token Launch",
      description: "Introduction of KOOKI token and NFT marketplace integration.",
      status: "In Progress",
      icon: "💎"
    },
    {
      date: "Q3 2024",
      title: "Land Sale Phase 1",
      description: "First wave of virtual land plots available for purchase.",
      status: "Upcoming",
      icon: "🌍"
    },
    {
      date: "Q4 2024",
      title: "Social Features",
      description: "Implementation of social hubs, chat systems, and community spaces.",
      status: "Upcoming",
      icon: "👥"
    },
    {
      date: "Q1 2025",
      title: "Creator Tools",
      description: "Release of advanced building and customization tools for landowners.",
      status: "Planned",
      icon: "🛠️"
    },
    {
      date: "Q2 2025",
      title: "Marketplace 2.0",
      description: "Enhanced marketplace with user-created items and virtual goods.",
      status: "Planned",
      icon: "🏪"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white py-20">
      <div className="max-w-7xl mx-auto px-4">
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-5xl font-bold mb-12 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text text-center"
        >
          KAOS UNIVERSE Roadmap
        </motion.h1>
        
        <div className="relative">
          {/* Vertical line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-purple-500 to-pink-500"></div>
          
          <div className="space-y-16">
            {timelineEvents.map((event, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`flex items-center ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
              >
                <div className={`w-1/2 ${index % 2 === 0 ? 'pr-12 text-right' : 'pl-12'}`}>
                  <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-2xl shadow-xl hover:shadow-purple-500/20 transition-shadow">
                    <div className="text-4xl mb-4">{event.icon}</div>
                    <h3 className="text-2xl font-bold text-purple-400 mb-2">{event.title}</h3>
                    <p className="text-gray-400 mb-2">{event.description}</p>
                    <div className={`inline-block px-3 py-1 rounded-full text-sm font-semibold
                      ${event.status === 'Completed' ? 'bg-green-500/20 text-green-400' :
                        event.status === 'In Progress' ? 'bg-blue-500/20 text-blue-400' :
                        event.status === 'Upcoming' ? 'bg-yellow-500/20 text-yellow-400' :
                        'bg-gray-500/20 text-gray-400'}`}>
                      {event.status}
                    </div>
                  </div>
                </div>
                <div className="w-4 h-4 bg-purple-500 rounded-full absolute left-1/2 transform -translate-x-1/2 z-10"></div>
                <div className={`w-1/2 ${index % 2 === 0 ? 'pl-12' : 'pr-12 text-right'}`}>
                  <h4 className="text-xl font-bold text-purple-300">{event.date}</h4>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline; 