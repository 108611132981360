import React from 'react';
import UnityGame from '../components/UnityGame';

const Sandbox = () => {
  const unityConfig = {
    loaderUrl: '/unity/KAOSUniverse.loader.js',
    dataUrl: '/unity/KAOSUniverse.data',
    frameworkUrl: '/unity/KAOSUniverse.framework.js',
    codeUrl: '/unity/KAOSUniverse.wasm',
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="h-[calc(100vh-5rem)]">
        <UnityGame {...unityConfig} />
      </div>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold text-purple-400 mb-4">KAOS Universe Metaverse</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="border border-purple-500/20 rounded-lg p-4">
              <h3 className="text-xl font-bold mb-2 text-purple-300">Available Plots</h3>
              <ul className="space-y-2 text-gray-300">
                <li className="flex justify-between items-center">
                  <span>XL Plots</span>
                  <span className="text-purple-400">1000 KOOKI</span>
                </li>
                <li className="flex justify-between items-center">
                  <span>L Plots</span>
                  <span className="text-purple-400">500 KOOKI</span>
                </li>
                <li className="flex justify-between items-center">
                  <span>M Plots</span>
                  <span className="text-purple-400">250 KOOKI</span>
                </li>
                <li className="flex justify-between items-center">
                  <span>S Plots</span>
                  <span className="text-purple-400">100 KOOKI</span>
                </li>
              </ul>
            </div>
            <div className="border border-purple-500/20 rounded-lg p-4">
              <h3 className="text-xl font-bold mb-2 text-purple-300">Controls</h3>
              <ul className="space-y-2 text-gray-300">
                <li>WASD - Movement</li>
                <li>Mouse - Look around</li>
                <li>E - Interact</li>
                <li>Space - Jump</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sandbox; 